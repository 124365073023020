import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { CardActionArea } from '@mui/material';
import CartItem from "./CartItem";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { removeBoxItem, decrementBoxItemQuantity, incrementBoxItemQuantity } from "state/BoxSlice";
import { addToCart } from "state/CartSlice";

import FlexBetween from "components/FlexBetween";
import { useSelector, useDispatch } from "react-redux";

import { generateId } from 'utils';


const BoxContent = ({products, handleSwitchStep}) => {
	const theme = useTheme();
    const dispatch = useDispatch();

    let box = useSelector(state => state.box.box);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);

    const handleAddToCart = (product) => {
        console.log(product);
        dispatch(addToCart(product));
        handleSwitchStep();
    };

    useEffect(() => {
        let totalQuantity = 0
        let totalPrice = 0
        box.forEach(item => {
          totalQuantity += parseInt(item.quantityToBuy);
          totalPrice += parseInt(item.product.unitPrice) * parseInt(item.quantityToBuy);
        });
        setTotalQuantity(totalQuantity);
        setTotalPrice(totalPrice);
        console.log(totalPrice);
        console.log(totalQuantity);
      }, [box]);

    return (
        <Box 
            textAlign='center'
            sx={{
                width: '100%',
                border: "none",
                // borderRadius: "10%",
                // borderColor: theme.palette.secondary.light,
        }}>
            <FlexBetween width='100%'>
                <Box sx={{
                    width: "30%",
                    // backgroundColor: theme.palette.secondary.alt,
                }}>
                    <Typography sx={{
                        border: 1,
                        width: "100%",
                        borderRadius: "10rem 0rem 0rem 10rem",
                        borderColor: theme.palette.secondary.light,
                        backgroundColor: totalQuantity < 7 ? `${theme.palette.neutral.main}` : `${theme.palette.secondary.light}`,
                        color: "white"
                    }}
                    >
                        MINI
                    </Typography>
                </Box>
                <Box sx={{
                    width: "38%",
                    // backgroundColor: theme.palette.secondary.alt,
                }}>
                    <Typography
                        sx={{
                            border: 1,
                            width: "100%",
                            backgroundColor: totalQuantity < 7 ? `${theme.palette.neutral.main}` : `${theme.palette.secondary.light}`,
                            color: "white"
                        }}
                    >
                        REGULAR
                    </Typography>
                </Box>
                <Box sx={{
                    width: "30%",
                    backgroundColor: theme.palette.secondary.alt,
                }}>
                    <Typography
                        sx={{
                            width: "100%",
                            border: 1,
                            color: "white",
                            borderRadius: "0rem 10rem 10rem 0rem",
                            borderColor: theme.palette.secondary.light,
                            backgroundColor: totalQuantity < 13 ? `${theme.palette.neutral.main}` : `${theme.palette.secondary.light}`,
                        }}
                    >
                        BIG
                    </Typography>
                </Box>
            </FlexBetween><br></br><br></br>
            <Box sx={{
                border: 1,
                borderRadius: "1%",
                padding: "8%",
                borderColor: theme.palette.secondary.light,
            }}
            >
                <Box >
                    <Typography textAlign="left" variant="h4" fontWeight="300">
                    Box Contents
                    </Typography>
                </Box><br></br>
                {
                box?.map(({id, product, quantityToBuy}) => 
                <CartItem
                    key={id}
                    id={id}
                    name={product.name}
                    unitPrice={product.unitPrice}
                    description={product.description}
                    quantityToBuy={quantityToBuy}
                    category={product.category}
                    image={product.image}
                    removeItem={removeBoxItem}
                    decrementQuantity={decrementBoxItemQuantity}
                    incrementQuantity={incrementBoxItemQuantity}
                />
                )
                }
                <Box textAlign="left">
                    <Typography
                        fontSize="1rem"
                        fontWeight="bold"
                        sx={{ color: theme.palette.secondary[200] }}
                        variant="h3"
                    >
                        Box Summary
                    </Typography>
                </Box>
                <br></br>
                <FlexBetween>
                    <Typography
                        sx={{ fontSize: 14, fontWeight: "bold" }}
                        color={theme.palette.secondary.light}
                        gutterBottom
                    >
                        Total Quantity:
                    </Typography>
                    <Typography variant="p" component="div">
                        {totalQuantity} items
                    </Typography>
                </FlexBetween>
                <FlexBetween>
                    <Typography
                        sx={{ fontSize: 14, fontWeight: "bold" }}
                        color={theme.palette.secondary.light}
                        gutterBottom
                    >
                        Total Price:
                    </Typography>
                    <Typography variant="p" component="div">
                        UGX {totalPrice}
                    </Typography>
                </FlexBetween>
                <Button
                    fullWidth
                    sx={{
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.background.alt,
                    fontSize: "0.9em",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    '&:hover': {
                        bgcolor: theme.palette.background.main,
                        color: theme.palette.secondary.light,
                        border: 1,
                        borderColor: theme.palette.secondary.light,
                    }
                    }}
                    onClick={() => handleAddToCart({
                        id: generateId(10, 'aA#'),
                        product: {
                          name: generateId(10, 'aA#'),
                          unitPrice: totalPrice,
                          description: {...box},
                          quantity: totalQuantity,
                          category: 'BoxFox',
                          image: '',
                        },
                      })
                    }
                >
                    Add To Cart
                    <AddShoppingCartIcon sx={{ ml: "10px" }} />
                </Button>
            </Box><br></br><br></br>
        </Box>
    )
};

export default BoxContent;
