import React, { useEffect, useState } from "react";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  ArrowDropDownOutlined,
} from "@mui/icons-material";
import CircularProgress from '@mui/material/CircularProgress';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import FlexBetween from "components/FlexBetween";
import { useDispatch, useSelector } from "react-redux";
import { setMode } from "state/globalSlice";
import profileImage from "assets/profile.jpeg";
import {
  AppBar,
  Button,
  Box,
  Typography,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Toolbar,
  Menu,
  MenuItem,
  Tooltip,
  Card,
  CardActions,
  CardContent,
  useTheme,
} from "@mui/material";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { removeCartItem, incrementCartItemQuantity, decrementCartItemQuantity, incrementBoxItemQuantity } from "state/CartSlice";
import { useNavigate } from "react-router-dom";
import CartItem from "./CartItem";

const Navbar = ({ user, isSidebarOpen, setIsSidebarOpen }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  let isLoading = useSelector(state => state.global.loading);

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleCheckOut = () => {
    console.log('checking outtt >>>>>');
  }

  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const isMenuOpen = Boolean(anchorMenuEl);
  const handleMenuClick = (event) => setAnchorMenuEl(event.currentTarget);
  const handleMenuClose = () => setAnchorMenuEl(null);

  const [viewCart, setViewCart] = useState(false);
  const cart = useSelector(state => state.cart.cart);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const navigate = useNavigate();

  console.log(cart);

  const handleCloseCart = () => setViewCart(false);


  useEffect(() => {
    let totalQuantity = 0
    let totalPrice = 0
    cart.forEach(item => {
      totalQuantity += parseInt(item.quantityToBuy);
      totalPrice += parseInt(item.product.unitPrice) * parseInt(item.quantityToBuy);
    });
    setTotalQuantity(totalQuantity);
    setTotalPrice(totalPrice);
  }, [cart]);

  return (
    <AppBar
      sx={{
        position: "fixed",
        background: "none",
        boxShadow: "0 8px 6px -6px grey",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <Button
            onClick={handleMenuClick}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textTransform: "none",
              gap: "1rem",
            }}
          >
            <Box textAlign="left">
              <Typography
                fontWeight="bold"
                fontSize="0.85rem"
                sx={{ color: theme.palette.secondary[100] }}
              >
                {user.name}
              </Typography>
              <Typography
                fontSize="0.75rem"
                sx={{ color: theme.palette.secondary[200] }}
              >
                {user.occupation}
              </Typography>
            </Box>
            <MenuIcon
              sx={{ color: theme.palette.secondary[300], fontSize: "1.5rem" }}
            />
          </Button>
          <Menu
            anchorEl={anchorMenuEl}
            open={isMenuOpen}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Tooltip
              title='Choose from a range of readily prepared gift boxes whose components are thoughtfully selected by our curators to impress'
            >
              <MenuItem onClick={() => { handleMenuClose(); navigate("/products/readybox"); }}>Ready Box</MenuItem>
            </Tooltip>
            <Tooltip
              title='Curate your own gift box by selecting items that you would like to add to your gift box. Put your curative spirits at play'
            >
              <MenuItem onClick={() => { handleMenuClose(); navigate("/products/boxfox"); }}>Build A Box</MenuItem>
            </Tooltip>
            <Tooltip
              title='Visit our marketplace to buy individual gift items'
            >
              <MenuItem onClick={() => { handleMenuClose(); navigate("/products/mart"); }}>The Curatory Mart</MenuItem>
            </Tooltip>
          </Menu>
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">
          <Tooltip title="Dark/Light Mode">
            <IconButton onClick={() => dispatch(setMode())}>
              {theme.palette.mode === "dark" ? (
                  <DarkModeOutlined sx={{ fontSize: "1.5rem" }} />
                  ) : (
                    <LightModeOutlined sx={{ fontSize: "1.5rem" }} />
                    )}
            </IconButton>
          </Tooltip>
          <ListItemButton
            onClick={() => setViewCart(true)}
            sx={{
              borderRadius: '5rem',
              backgroundColor:
                totalQuantity >= 1
                  ? theme.palette.secondary.light
                  : "transparent",
              color:
                totalQuantity >= 1
                  ? theme.palette.background.alt
                  : theme.palette.secondary[100],
              '&:hover': {
                bgcolor: theme.palette.background.main,
                color: theme.palette.secondary.light,
              },
            }}
            disabled={totalQuantity < 1}
          >
            <ListItemIcon
              sx={{
                ml: "2rem",
                color:
                  totalQuantity >= 1
                    ? theme.palette.background.alt
                    : theme.palette.secondary[200],
                '&:hover': {
                  bgcolor: theme.palette.background.main,
                  color: theme.palette.secondary.light,
                },
              }}
            >
              {
                totalQuantity < 1 ?
                  <Tooltip title='View your cart and Check Out'>
                    <ShoppingCartOutlinedIcon sx={{ fontSize: "1.5rem" }} />
                  </Tooltip> :
                  <Tooltip title='View your cart and Check Out'>
                    <ShoppingCartIcon sx={{ fontSize: "1.5rem" }} />
                  </Tooltip>
              }
            </ListItemIcon>
            <ListItemText primary={totalQuantity} />
          </ListItemButton>
          <FlexBetween>
            <Button
              onClick={handleClick}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                gap: "1rem",
              }}
            >
              <Box
                component="img"
                alt="profile"
                src={profileImage}
                height="32px"
                width="32px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              />
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.85rem"
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user.name}
                </Typography>
                <Typography
                  fontSize="0.75rem"
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  {user.occupation}
                </Typography>
              </Box>
              <ArrowDropDownOutlined
                sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <MenuItem onClick={handleClose}>Log Out</MenuItem>
            </Menu>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
      <Dialog open={viewCart} onClose={handleCloseCart} fullWidth>
        <DialogTitle
          sx={{
            color: theme.palette.secondary.light,
            fontWeight: "bold",
          }}
        >
          Shopping Cart
        </DialogTitle>
        <DialogContent>
          {
            cart?.map(({id, product, quantityToBuy}) => 
              <CartItem
                key={id}
                id={id}
                name={product.name}
                unitPrice={product.unitPrice}
                description={product.description}
                quantityToBuy={quantityToBuy}
                category={product.category}
                image={product.image}
                removeItem={removeCartItem}
                incrementQuantity={incrementCartItemQuantity}
                decrementQuantity={decrementCartItemQuantity}
              />
            )
          }<br></br>
          <Card
            sx={{
              backgroundImage: "none",
              borderRadius: "0.55rem",
            }}
          >
            <CardContent>
              <Box textAlign="left">
                <Typography
                  fontSize="1rem"
                  fontWeight="bold"
                  sx={{ color: theme.palette.secondary[200] }}
                  variant="h3"
                >
                  Order Summary
                </Typography>
              </Box>
              <br></br>
              <FlexBetween>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "bold" }}
                  color={theme.palette.secondary.light}
                  gutterBottom
                >
                  Total Quantity:
                </Typography>
                <Typography variant="p" component="div">
                  {totalQuantity} items
                </Typography>
              </FlexBetween>
              <FlexBetween>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "bold" }}
                  color={theme.palette.secondary.light}
                  gutterBottom
                >
                  Total Price:
                </Typography>
                <Typography variant="p" component="div">
                  UGX {totalPrice}
                </Typography>
              </FlexBetween>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            sx={{
              backgroundColor: theme.palette.background.main,
              color: theme.palette.secondary.light,
              border: 1,
              borderColor: theme.palette.secondary.light,
              fontSize: "0.9em",
              fontWeight: "bold",
              padding: "10px 20px",
              '&:hover': {
                bgcolor: theme.palette.background.main,
                color: theme.palette.secondary.light,
              }
            }}
            onClick={handleCloseCart}
          >
            Close
          </Button>
          <Button
            fullWidth
            onClick={handleCheckOut}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "0.9em",
              fontWeight: "bold",
              padding: "10px 20px",
              '&:hover': {
                bgcolor: theme.palette.background.main,
                color: theme.palette.secondary.light,
                border: 1,
                borderColor: theme.palette.secondary.light,
              }
            }}
          >
            Checkout
            {
              isLoading ? <CircularProgress /> :
                <ShoppingCartCheckoutIcon sx={{ ml: "10px" }} />
            }
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
};

export default Navbar;
