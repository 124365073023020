import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cart: [],
  },
  reducers: {
		addToCart: (state, action) => {
      console.log('state >>>>>>>>>>>>> ', state.cart);
			const itemInCart = state.cart.find((item) => item.id === action.payload.id);
			if (itemInCart) {
        itemInCart.quantityToBuy++;
			} else {
        state.cart.push({ ...action.payload, quantityToBuy: 1 });
        console.log(state.cart);
			}
    },
    incrementCartItemQuantity: (state, action) => {
      const item = state.cart.find((item) => item.id === action.payload);
      item.quantityToBuy++;
    },
    decrementCartItemQuantity: (state, action) => {
      const item = state.cart.find((item) => item.id === action.payload);
      if (item.quantityToBuy === 1) {
        item.quantityToBuy = 1;
      } else {
        item.quantityToBuy--;
      }
    },
    removeCartItem: (state, action) => {
      const removeItem = state.cart.filter((item) => item.id !== action.payload);
      state.cart = removeItem;
    },
  },
});

export const cartReducer = cartSlice.reducer;
export const {
  addToCart,
  incrementCartItemQuantity,
  decrementCartItemQuantity,
  removeCartItem,
  addToBox,
  incrementBoxItemQuantity,
  decrementBoxItemQuantity,
  removeBoxItem,
} = cartSlice.actions;
