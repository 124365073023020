import { createSlice } from '@reduxjs/toolkit';

const boxSlice = createSlice({
  name: 'box',
  initialState: {
    box: [],
  },
  reducers: {
    addToBox: (state, action) => {
      console.log('state >>>>>>>>>>>>> ', state.box);
			const itemInBox = state.box.find((item) => item.id === action.payload.id);
			if (itemInBox) {
        itemInBox.quantityToBuy++;
			} else {
        state.box.push({ ...action.payload, quantityToBuy: 1 });
			}
    },
    incrementBoxItemQuantity: (state, action) => {
      const item = state.box.find((item) => item.id === action.payload);
      item.quantityToBuy++;
    },
    decrementBoxItemQuantity: (state, action) => {
      const item = state.box.find((item) => item.id === action.payload);
      if (item.quantityToBuy === 1) {
        item.quantityToBuy = 1;
      } else {
        item.quantityToBuy--;
      }
    },
    removeBoxItem: (state, action) => {
      const removeItem = state.box.filter((item) => item.id !== action.payload);
      state.box = removeItem;
    },
  },
});

export const boxReducer = boxSlice.reducer;
export const {
  addToBox,
  incrementBoxItemQuantity,
  decrementBoxItemQuantity,
  removeBoxItem,
} = boxSlice.actions;
