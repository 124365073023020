import React, { useEffect } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
	CardMedia,
	IconButton,
  Collapse,
  Button,
  Typography,
	Tooltip,
	useTheme,
} from "@mui/material";
import FlexBetween from "./FlexBetween";
import { useDispatch, useSelector } from "react-redux";
import { removeCartItem, incrementCartItemQuantity, decrementCartItemQuantity } from "state/CartSlice";
import CancelIcon from '@mui/icons-material/Cancel';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import AddBoxIcon from '@mui/icons-material/AddBox';

const CartItem = ({
	id,
	name,
	unitPrice,
	description,
	quantityToBuy,
	category,
	image,
	removeItem,
	decrementQuantity,
	incrementQuantity
}) => {
	const theme = useTheme();
	const dispatch = useDispatch();

	const totalPrice = (itemUnitPrice, itemQuantityToBuy) => parseInt(itemUnitPrice) * parseInt(itemQuantityToBuy);

	return (
		<Box>
			<Card
		sx={{
		  backgroundImage: "none",
		  borderRadius: "0.55rem",
		}}
	  >
		<CardContent>
		  <Box>
						<Typography
								sx={{ fontSize: 11 }}
								color={theme.palette.secondary.grey}
								gutterBottom
						>
							{category}
						</Typography>
		  </Box><br></br>
		  <FlexBetween>
						<Box>
							<Typography
				sx={{ fontSize: 13 }}
				color={theme.palette.grey[500]}
				gutterBottom
			  >
				Product
			  </Typography>
			  <Typography
				sx={{ fontSize: 13 }}
				color={theme.palette.secondary.alt}
				fontSize="bold"
				gutterBottom
			  >
				{name}
			  </Typography>
			</Box>
			<Box m="0rem 1rem 0rem 1rem">
			  |
			</Box>
						<Box>
							<Typography
				sx={{ fontSize: 13 }}
				color={theme.palette.grey[500]}
				gutterBottom
			  >
				Unit(UGX)
			  </Typography>
			  <Typography
				sx={{ fontSize: 13 }}
				color={theme.palette.secondary.grey}
				gutterBottom
			  >
				{unitPrice}
			  </Typography>
						</Box>
						<Box m="0rem 1rem 0rem 1rem">
			  |
			</Box>
			<Box textAlign='center'>
							<Typography
				sx={{ fontSize: 13 }}
				color={theme.palette.grey[500]}
				gutterBottom
			  >
				Quantity
			  </Typography>
			  <Typography
				sx={{ fontSize: 13 }}
				color={theme.palette.secondary.grey}
				gutterBottom
			  >
				{quantityToBuy}
			  </Typography>
						</Box>
						<Box m="0rem 1rem 0rem 1rem">
			  |
			</Box>
						<Box>
							<Typography
				sx={{ fontSize: 13 }}
				color={theme.palette.grey[500]}
				gutterBottom
			  >
				Total
			  </Typography>
			  <Typography
				sx={{ fontSize: 13 }}
				color={theme.palette.grey[500]}
				gutterBottom
			  >
				{totalPrice(unitPrice, quantityToBuy)}
			  </Typography>
						</Box>
						<Box m="0rem 1rem 0rem 1rem">
			  |
			</Box>
						<Box>
							<Tooltip
								title="Reduce Quantity"
								sx={{ ml: "2rem" }}
							>
								<IconButton
									onClick={() => dispatch(decrementQuantity(id))}
									sx={{
										color: theme.palette.secondary.light,
									}}
								>
									<IndeterminateCheckBoxIcon />
								</IconButton>
							</Tooltip>
						</Box>
						<Box>
							<Tooltip
								title="Add Quantity"
								sx={{ ml: "2rem" }}
							>
								<IconButton
									onClick={() => dispatch(incrementQuantity(id))}
									sx={{
										color: theme.palette.secondary.light,
									}}
								>
									<AddBoxIcon />
								</IconButton>
							</Tooltip>
						</Box>
						<Box>
							<Tooltip
								title="Remove Item"
								sx={{ ml: "2rem" }}
							>
								<IconButton
									onClick={() => dispatch(removeItem(id))}
									sx={{
										color: theme.palette.secondary.grey,
									}}
								>
									<CancelIcon />
								</IconButton>
							</Tooltip>
						</Box>
		  </FlexBetween>
		</CardContent>
	  </Card><br></br>
		</Box>
	);
};

export default CartItem;
