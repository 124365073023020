import React, { useEffect } from "react";
import {
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ReadyBoxImage2 from 'assets/readyboximage2.jpg';
import ReadyBoxImage3 from 'assets/readyboximage3.jpg';
import ReadyBoxImage4 from 'assets/readyboximage4.jpg';
import GiftBoxImage4 from 'assets/giftimage4.jpg';
import FlexBetween from "components/FlexBetween";
import Product from "components/Product";
import { addToCart } from "state/CartSlice";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';


const Products = () => {
  let data = useSelector(state => state.products.products);
  const dispatch = useDispatch();

  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const [isLoading, setIsLoading] = React.useState(false);
  const [products, setProducts] = React.useState(data);

  const handleAddToCart = (product) => {
    console.log(product);
    dispatch(addToCart(product));
  };

  useEffect(() => {
      const products = data.filter((product) => product.product.category === "Ready Box");
      setProducts(products);
  }, [data]);

  return (
    <Box m="4.05rem 2rem 2.5rem 2rem">
      <FlexBetween>
        <Box maxWidth='50%' >
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showStatus={false}
            renderArrowNext={() => false}
            renderArrowPrev={() => false}
            width='100%'
          >
            <Box
              component="img"
              alt="profile"
              src={GiftBoxImage4}
              height="20rem"
            />
            <Box
              component="img"
              alt="profile"
              src={ReadyBoxImage2}
              height="20rem"
            />
            <Box
              component="img"
              alt="profile"
              src={ReadyBoxImage3}
              height="20rem"
            />
            <Box
              component="img"
              alt="profile"
              src={ReadyBoxImage4}
              height="20rem"
            />
          </Carousel>
        </Box>
        <Box textAlign='left' maxWidth='50%' ml='3%'>
          <Box >
            <Typography variant="h2" fontWeight="800">
              READY BOX
            </Typography>
          </Box><br></br>
          <Box width='80%'>
            <Typography variant="h4" fontWeight="200">
              This is a collection of carefully curated,
              thematic gift boxes by our team of thoughtful curators.
              Whether it’s for a birthday, big day or just because,
              we scour far and wide for the best products so that each box
              tells a story of thoughtfulness and intention!
            </Typography>
          </Box><br></br>
        </Box>
      </FlexBetween>
      <FlexBetween>
        <Box >
          <Typography variant="h2" fontWeight="300">
            Ready Boxes
          </Typography>
        </Box>
      </FlexBetween><br></br>

      {products || !isLoading ? (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          {products.map(
            ({
              id,
              product
            }) => (
              <Product
                key={id}
                id={id}
                name={product.name}
                description={product.description}
                unitPrice={product.unitPrice}
                category={product.category}
                quantity={product.quantity}
                image={product.image}
                buttonText={"Add To Cart"}
                buttonIcon={<AddShoppingCartIcon sx={{ ml: "10px" }} />}
                onClick={() => handleAddToCart({
                  id: id,
                  product: {
                    name: product.name,
                    unitPrice: product.unitPrice,
                    description: product.description,
                    quantity: product.quantity,
                    category: product.category,
                    image: product.image,
                  },
                })}
              />
            )
          )}
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
};

export default Products;
