import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { db, storage } from "../config";
import { collection, doc, addDoc, getDocs, deleteDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const collectionRef = collection(db, "products")

export const fetchProducts = createAsyncThunk(
    'products/fetchProducts',
    async () => {
        // console.log(':::::::::::::>>>>>> in here');
        const querySnaphot = await getDocs(collectionRef);
        const products = querySnaphot.docs.map(doc => ({
            id: doc.id,
            product: doc.data(),
        }));

        return products;
    }
);

export const deleteProduct = createAsyncThunk(
    'products/deleteProduct',
    async id => {
        await deleteDoc(doc(db, 'products', id));

        return id;
    }
)

export const deleteAllProducts = createAsyncThunk(
    'products/deleteAllProducts',
    async products => {
        await products.forEach(product => {
            deleteDoc(doc(db, 'products', product.id));
        });

        return [];
    }
)

const productSlice = createSlice({
    name: 'Products',
    initialState: {
        products: [],
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.products = action.payload;
            })
            .addCase(deleteProduct.fulfilled, (state, action) => {
                state.products = state.products.filter(product => product.id !== action.payload);
            })
    }
})

export default productSlice.reducer;
