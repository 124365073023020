import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import storage from 'redux-persist/lib/storage';
import globalReducer from "state/globalSlice";
import productsReducer from "state/ProductSlice";
import { cartReducer } from "state/CartSlice";
import { boxReducer } from "state/BoxSlice";

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({ 
  global: globalReducer,
  products: productsReducer,
  cart: cartReducer,
  box: boxReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }))
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
