import React, { useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  Stepper,
  Step,
  StepLabel,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import FlexBetween from "components/FlexBetween";
import Product from "components/Product";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "state/CartSlice";
import { addToBox } from "state/BoxSlice";
import BoxContent from "components/BoxContent";

const steps = ['Choose Your Box Color', 'Choose Your Items', 'Choose Your Card', 'DONE!'];
const optionalSteps = [];

const BoxFox = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  let data = useSelector(state => state.products.products);
  let boxfoxProducts = useSelector(state => state.products.products);

  const [products, setProducts] = React.useState(data);
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const [isLoading, setIsLoading] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [boxColor, setBoxColor] = React.useState('');

  const handleAddToBox = (product) => {
    dispatch(addToBox(product));
  };

  const handleSwitchStep = event => {
    handleNext();
  }

  useEffect(() => {
      const products = data.filter((product) => product.product.category === "Single Item");
      setProducts(products);
  }, [data]);

  const isStepOptional = (step) => {
    return optionalSteps?.[step];
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box m="4.05rem 2rem 2.5rem 2rem">
      <br></br>
      <Stepper alternativeLabel activeStep={activeStep}>
        {
          steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step
                key={label}
                {...stepProps}
                sx={{
                  '& .MuiStepLabel-root .Mui-completed': {
                    color: theme.palette.secondary.light, // circle color (COMPLETED)
                  },
                  '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                    {
                      color: theme.palette.secondary.light, // Just text label (COMPLETED)
                    },
                  '& .MuiStepLabel-root .Mui-active': {
                    color: theme.palette.secondary, // circle color (ACTIVE)
                  },
                  '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                    {
                      color: theme.palette.secondary.light, // Just text label (ACTIVE)
                    },
                  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                    fill: theme.palette.secondary.light, // circle's number (ACTIVE)
                  },
                }}
              >
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })
        }
      </Stepper>
      <br></br>
      {
        activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button
              fullWidth
              variant="outlined"
              size="small"
              // onClick={() => handleAddToCart({
              //   id: id,
              //   product: {
              //     name,
              //     unitPrice,
              //     description,
              //     quantity,
              //     category,
              //     image,
              //   },
              // })}
              sx={{
                color: theme.palette.background.alt,
                backgroundColor: theme.palette.secondary.light,
                fontSize: "0.9em",
                fontWeight: "bold",
                padding: "10px 20px",
                '&:hover': {
                  color: theme.palette.secondary.light,
                  bgcolor: theme.palette.background.main,
                  border: 1,
                  borderColor: theme.palette.background.main,
                }
              }}
            >
              Add To Cart
              <AddShoppingCartIcon sx={{ ml: "10px" }} />
            </Button>
          </React.Fragment>
        ) :
          (
            <React.Fragment>
              {
                activeStep === 0 &&
                (
                  <Box textAlign='center'>
                    <FlexBetween margin='2rem 30rem 0 10rem'>
                      <Tooltip
                        title="Go back to the previous step"
                        sx={{ ml: "12rem" }}
                      >
                        <IconButton
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{
                            color: theme.palette.secondary.light,
                          }}
                        >
                          <ArrowBackIosNew />
                          Back
                        </IconButton>
                      </Tooltip>
                      <Typography variant="h2" fontWeight="400">
                        Choose Your Box Color
                      </Typography>
                    </FlexBetween><br></br>
                    <Typography variant='h5' fontWeight="200">
                      Welcome to the easiest way to send someone a custom gift, in 3 simple steps.
                    </Typography>
                    <Typography variant='h5' fontWeight="200">
                      Add to cart and repeat for multiple boxes, or update your quantity in the cart.
                    </Typography>
                    <FlexBetween margin='5rem 25rem 0 25rem' alignItems="center">
                      <Box
                        onClick={() => { setBoxColor('Creme');  handleNext(); }}
                        sx={{
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                      >
                        <Box
                          component='div'
                          width='10rem'
                          height='10rem'
                          sx={{
                            borderRadius: '3rem',
                            backgroundColor: "#FFFDD0",
                            '&:hover': {
                              cursor: 'pointer',
                            },
                          }}
                        /><br></br>
                        <Typography variant="p" fontWeight="800">
                          Creme
                        </Typography>
                      </Box>
                      <Box
                        onClick={() => { setBoxColor('Black');  handleNext(); }}
                        sx={{
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                      >
                        <Box
                          component='div'
                          width='10rem'
                          height='10rem'
                          sx={{
                            borderRadius: '2rem',
                            backgroundColor: "#28282b",
                            '&:hover': {
                              // padding: '11rem',
                              // width: '15rem',
                              // height: '15rem',
                              cursor: 'pointer',
                            },
                          }}
                        /><br></br>
                        <Typography variant="p" fontWeight="800">
                          Matte Black
                        </Typography>
                      </Box>
                    </FlexBetween>
                    {/* </Box> */}
                  </Box>
                )
              }
              {
                activeStep === 1 &&
                (
                  <Box >
                    <FlexBetween margin='2rem 30rem 0 10rem'>
                      <Tooltip
                        title="Go back to the previous step"
                        sx={{ ml: "12rem" }}
                      >
                        <IconButton
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{
                            color: theme.palette.secondary.light,
                          }}
                        >
                          <ArrowBackIosNew />
                          Back
                        </IconButton>
                      </Tooltip>
                      <Typography variant="h2" fontWeight="400">
                        Choose Your Items
                      </Typography>
                    </FlexBetween><br></br>
                    <Typography textAlign='center' variant='h5' fontWeight="200">
                      We’ve hand-selected the best products in one place. Select from the items below and fill up your box!
                    </Typography><br></br>
                    <Typography textAlign='center' variant='h5' fontWeight="200">
                      Pick your products first and we will automatically select the box size that fits best.
                    </Typography>
                    <Box m="4.05rem 6rem 2.5rem 6rem">
                      <BoxContent 
                        products={boxfoxProducts}
                        handleSwitchStep={event => handleSwitchStep(event)}
                      />
                      <FlexBetween>
                        <Box >
                          <Typography variant="h4" fontWeight="300">
                            Products
                          </Typography>
                        </Box>
                      </FlexBetween><br></br>
                      {products || !isLoading ? (
                        <Box
                          mt="20px"
                          display="grid"
                          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                          justifyContent="space-between"
                          rowGap="20px"
                          columnGap="1.33%"
                          sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                          }}
                        >
                          {products.map(
                            ({
                              id,
                              product
                            }) => (
                              <Product
                                key={id}
                                id={id}
                                name={product.name}
                                description={product.description}
                                unitPrice={product.unitPrice}
                                category={product.category}
                                quantity={product.quantity}
                                image={product.image}
                                buttonText={"Add To Box"}
                                buttonIcon={<AddCircleIcon sx={{ ml: "10px" }}/>}
                                onClick={() => handleAddToBox({
                                  id: id,
                                  product: {
                                    name: product.name,
                                    unitPrice: product.unitPrice,
                                    description: product.description,
                                    quantity: product.quantity,
                                    category: product.category,
                                    image: product.image,
                                  },
                                })}
                              />
                            )
                          )}
                        </Box>
                      ) : (
                        <>Loading...</>
                      )}
                    </Box>
                  </Box>
                )
              }
            </React.Fragment>
          )
      }
    </Box>
  );
};

export default BoxFox;
