import React, { useState } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { CardActionArea } from '@mui/material';

import FlexBetween from "components/FlexBetween";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "state/CartSlice";

const Product = ({
  id,
  name,
  unitPrice,
  description,
  quantity,
  category,
  image,
  buttonText,
  buttonIcon,
  onClick
}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(true);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewProduct = () => {
    setOpen(true);
  };

  return (
    <Card
      sx={{
        backgroundImage: "none",
        borderRadius: "0.55rem",
      }}
    >
      <CardActionArea
        onClick={() => handleViewProduct({
          id, name, unitPrice, description, quantity, category, image,
        })}
      >
        <CardMedia
          component="img"
          height="140"
          image={image}
          alt="product image"
        />
        <CardContent>
          <Typography
            sx={{ fontSize: 14 }}
            color={theme.palette.secondary.light}
            gutterBottom
            >
            {category}
          </Typography>
          <Typography variant="h5" component="div">
            {name}
          </Typography>
          <Typography sx={{ mb: "1.5rem" }} color={theme.palette.neutral[300]}>
            UGX {Number(unitPrice).toFixed(2)}
          </Typography>
          {/* <Rating value={rating} readOnly /> */}

          <Typography variant="body2">{description}</Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          fullWidth
          variant="outlined"
          size="small"
          onClick={onClick}
          sx={{
            color: theme.palette.background.alt,
            backgroundColor: theme.palette.secondary.light,
            fontSize: "0.9em",
            fontWeight: "bold",
            padding: "10px 20px",
            '&:hover': {
              color: theme.palette.secondary.light,
              bgcolor: theme.palette.background.main,
              border: 1,
              borderColor: theme.palette.background.main,
            }
          }}
        >
          {buttonText}
          {buttonIcon}
        </Button>
      </CardActions>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            color: theme.palette.secondary.light,
            fontWeight: "bold",
          }}
        >
          More about this product
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Here's a little more about this product
          </DialogContentText>
          <br /> */}
          <FlexBetween>
            <Box textAlign="center">
              <img src={image} alt={name} width="40%" />
            </Box>
            <Box>
            <Typography
              sx={{ fontSize: 14 }}
              color={theme.palette.secondary.light}
              gutterBottom
            >
              {category}
            </Typography>
            <Typography variant="h5" component="div">
              {name}
            </Typography>
            <Typography sx={{ mb: "1.5rem" }} color={theme.palette.neutral[300]}>
              UGX {Number(unitPrice).toFixed(2)}
            </Typography>
            {/* <Rating value={rating} readOnly /> */}

            <Typography variant="body2">{description}</Typography>
            </Box>
          </FlexBetween>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            sx={{
              backgroundColor: theme.palette.background.main,
              color: theme.palette.secondary.light,
              border: 1,
              borderColor: theme.palette.secondary.light,
              fontSize: "0.9em",
              fontWeight: "bold",
              padding: "10px 20px",
              '&:hover': {
                bgcolor: theme.palette.background.main,
                color: theme.palette.secondary.light,
              }
            }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default Product;
