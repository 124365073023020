import React, { useEffect } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  DownloadOutlined,
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import giftImage1 from "assets/giftimage1.jpg";
import giftImage2 from "assets/giftimage2.jpg";
import giftImage3 from "assets/giftimage3.jpg";
import giftImage4 from "assets/giftimage4.jpg";
import giftImage5 from "assets/giftimage5.jpg";

const Dashboard = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const dispatch = useDispatch();

  return (
    <Box
      m="4.05rem 0 2.5rem 0"
    >
      <Box>
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          showStatus={false}
          renderArrowNext={() => false}
          renderArrowPrevious={() => false}
        >
          <Box
            component="img"
            alt="profile"
            src={giftImage1}
            height="30rem"
          />
          <Box
            component="img"
            alt="profile"
            src={giftImage2}
            height="30rem"
          />
          <Box
            component="img"
            alt="profile"
            src={giftImage3}
            height="30rem"
          />
          <Box
            component="img"
            alt="profile"
            src={giftImage4}
            height="30rem"
          />
          <Box
            component="img"
            alt="profile"
            src={giftImage5}
            height="30rem"
          />
        </Carousel>
      </Box>
      <Box textAlign="center">
        <Typography variant="h1" fontWeight="800">
          YOUR GO-TO
        </Typography>
        <Typography variant="h1" fontWeight="bold" color="#D4AF37">
          GIFT SHOP
        </Typography>
      </Box><br></br>
      <Box textAlign="center">
        <Typography variant="h2" fontWeight="50">
          Making gifting a seamless experience for you
        </Typography>
      </Box><br></br>
      <Box
        mt="2rem"
        sx={{
          backgroundColor: "#f0e3b9",
          padding: "0.3rem 0 4rem 1.4rem"
        }}
      >
        <Box textAlign="center" mt="3rem">
          <Typography variant="h2" fontWeight="100">
            HOW IT WORKS
          </Typography>
        </Box><br></br>
        <Box ml="2rem">
          <Typography variant="h1" fontWeight="1000">
            1.
          </Typography>
          <Typography variant="h3" fontWeight="bold">
            NEED A GIFT?
          </Typography>
          <Typography variant="h4" fontWeight="200">
            Any reason, any season. We’re here to keep things thoughtful.
          </Typography>
        </Box><br></br>
        <Box ml="2rem">
          <Typography variant="h1" fontWeight="1000">
            2.
          </Typography>
          <Typography variant="h3" fontWeight="bold">
            KEEP IT PERSONAL
          </Typography>
          <Typography variant="h4" fontWeight="200">
          We curate the best products so you can create the most personal gifts.
          </Typography>
        </Box>
        <Box ml="2rem">
          <Typography variant="h1" fontWeight="1000">
            3.
          </Typography>
          <Typography variant="h3" fontWeight="bold">
            WE'LL HANDLE THE REST
          </Typography>
          <Typography variant="h4" fontWeight="200">
          We take your masterpiece and deliver it straight to their doorstep(or yours) -- you will both love it!
          </Typography>
        </Box>
      </Box><br></br><br></br>
    </Box>
  );
};

export default Dashboard;
